import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { isEmpty, getContentModel } from '@/lib/helper'
import { INLINES } from '@contentful/rich-text-types'
import { selectContentPromotionEvent } from '@/lib/ga-events'
import { useRouter } from 'next/router'
import { default as pagePaths } from '../../public/promotion-urls.json'
// import { markdownRenderOptions } from '@/constants/markdownRenderOptions'

const Promobar = (props) => {
  let pageModel = getContentModel('page')
  let contentModel = getContentModel('hellobar')
  const router = useRouter()
  const messageText = props?.message?.content[0]?.content[0]?.value || ''
  const renderOptions = {
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => {
        return (
          <a
            href={node.data.uri}
            onClick={(event) => {
              event.preventDefault()
              let currentPath = router.asPath;
              let promotionId = (process.env.NEXT_PUBLIC_VERCEL_URL || process.env.NEXT_PUBLIC_BASE_URL)+currentPath;
              promotionId = promotionId.startsWith('http') ? promotionId : 'https://'+promotionId
              let linkText = node?.content[0]?.value
              //PROMOTION NAME & CREATIVE NAME & CREATIVE SLOT & locationId
              let contentModelField = contentModel?.fields?.find(item => item.id == 'message')?.name || ''
              let promotionName
              let creativeName = props?.entryTitle || ''
              let creativeSlot = contentModelField+'::'+linkText
              let locationId = 'Promo Bar'
              if(currentPath == '/') {
                promotionName = `${pageModel?.name}::${contentModel?.name}`
              } else {
                let pageType = pagePaths.find(page => page.path == currentPath)
                promotionName = pageType?.type ? getContentModel(pageType?.type)?.name+`::${contentModel?.name}` : `${pageModel?.name}::${contentModel?.name}`
              }
              let eventData = [{
                promotion_id: promotionId,
                promotion_name: promotionName,
                creative_name: creativeName,
                creative_slot: creativeSlot,
                location_id: locationId
              }]
              dataLayer.push({ ecommerce: null })
              dataLayer.push({
                event: 'select_promotion',
                ecommerce: { items: eventData },
              })
              router.push(node.data.uri)
            }}
          >
            {children}
          </a>
        );
      },
    },
  }

  return (
    <>
      {messageText != '' && (
        <div className="promobar">
          {props.show ? (
            <div
              className="promobar__message flex items-center justify-center w-full h-full"
              style={{
                backgroundColor: `#${props.backgroundColor}`,
                color: `#${isEmpty(props.textColor) ? '#ffffff' : props.textColor}`,
              }}
            >
              {documentToReactComponents(props.message, renderOptions)}
            </div>
          ) : null}
        </div>
      )}
    </>
  )
}

export default Promobar
